import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import PostTemplate from "../components/PostTemplate";

const NewsPost = ({ data }) => {
  const {
    markdownRemark: post,
    site: {
      siteMetadata: { siteUrl },
    },
  } = data;

  return (
    <Layout>
      <PostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        headline={post.frontmatter.headline}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
        helmet={
          <Helmet titleTemplate="%s | News">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="keywords"
              content={post.frontmatter.tags?.join(",") || ""}
            />
          </Helmet>
        }
        image={post.frontmatter.image}
        tags={post.frontmatter.tags}
        url={`${siteUrl}${post.fields.slug}`}
      />
    </Layout>
  );
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        headline
        subtitle
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
